import { styled } from '@mui/material'
import { Tabs, Tab, IconButton, InputBase } from '@mui/material'

export const Currencies = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        visibility: 'hidden',
    }
}))

export const CurrenciesCurrency = styled(Tab)(({ theme }) => ({
    '&.Mui-selected': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
    }
}))

export interface ExchangeProps {
    active?: boolean
}
export const Exchange = styled('div')<ExchangeProps>(({ theme, active }) => ({
    flexGrow: active ? 1 : 0,
    display: 'flex',
    color: active ? theme.palette.primary.contrastText : undefined,
    backgroundColor: active ? theme.palette.primary.dark : undefined,
    '&:first-child': {
        borderTopRightRadius: 32,
        borderBottomRightRadius: 32,
    },
    '&:last-child': {
        borderTopLeftRadius: 32,
        borderBottomLeftRadius: 32,
    },
    [`& ${ExchangeButton.toString()}`]: {
        color:'red'
    }
}))

export const ExchangeButton = styled(IconButton)(() => ({
    //color: 'inherit'
}))

export const ExchangeInput = styled(InputBase)(() => ({
    color: 'inherit'
}))
