import { createTheme } from '@mui/material'
//import { grey } from '@mui/material/colors'

export const theme = createTheme({
//    palette: {
//        primary: {
//            main: grey['800']
//        }
//    }
})
