import { currencies, Currencies, CurrencyId, CurrencyExchangeType, getAValue, getBValue, getExchangeRate } from '../../services/ConvService'

export type State = {
    currencies: Currencies
    a: CurrencyId
    b: CurrencyId
    aValue: number
    bValue: number
    exchangeType: CurrencyExchangeType
    exchangeRate: number
}

export type Action =
    | { type:'setA', payload:CurrencyId }
    | { type:'setB', payload:CurrencyId }
    | { type:'setAValue', payload:number }
    | { type:'setBValue', payload:number }
    | { type:'setExchangeType', payload:CurrencyExchangeType }
    | { type:'setExchangeRate', payload:number }
;

export const initialState: State = {
    currencies,
    a: 'GEL',
    b: 'RUB',
    aValue: 1,
    bValue: getBValue(1, getExchangeRate('GEL', 'RUB', 'buy')),
    exchangeType: 'buy',
    exchangeRate: getExchangeRate('GEL', 'RUB', 'buy'),
}

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'setA': {
            const a = action.payload
            const exchangeRate = getExchangeRate(a, state.b, state.exchangeType)
            const bValue = getBValue(state.aValue, exchangeRate)
            return {
                ...state,
                a,
                bValue,
                exchangeRate,
            }
        }
        case 'setB': {
            const b = action.payload
            const exchangeRate = getExchangeRate(state.a, b, state.exchangeType)
            const bValue = getBValue(state.aValue, exchangeRate)
            return {
                ...state,
                b,
                bValue,
                exchangeRate,
            }
        }
        case 'setAValue': {
            return {
                ...state,
                aValue: action.payload,
                bValue: getBValue(action.payload, state.exchangeRate),
            }
        }
        case 'setBValue': {
            return {
                ...state,
                aValue: getAValue(action.payload, state.exchangeRate),
                bValue: action.payload,
            }
        }
        case 'setExchangeType': {
            const exchangeType = action.payload
            const exchangeRate = getExchangeRate(state.a, state.b, exchangeType)
            const bValue = getBValue(state.aValue, exchangeRate)
            return {
                ...state,
                bValue,
                exchangeType,
                exchangeRate,
            }
        }
        case 'setExchangeRate': {
            return {
                ...state,
                bValue: getBValue(state.aValue, action.payload),
                exchangeRate: action.payload
            }
        }
        default: {
            return state
        }
    }
}
