import React from 'react'

import { AppHeader } from 'packs/app/components/AppHeader/AppHeader'
import { Routes, Route } from 'react-router-dom'
import { About } from 'packs/app-about/components/About/About'
import { Conv } from 'packs/conv/components/Conv/Conv'

export function App() {

    return (
        <>

            <AppHeader/>

            <Routes>

                <Route
                    path="/about"
                    element={(
                        <About/>
                    )}
                />

                <Route
                    path="/"
                    element={(
                        <Conv/>
                    )}
                />

            </Routes>

        </>
    )
}
