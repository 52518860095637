import React from 'react'

import { AppBar, Container, Toolbar, IconButton } from '@mui/material'
import { Menu } from '@mui/icons-material'

export interface AppHeaderBarProps {
    children: React.ReactNode
    onClickMenu: () => void
}

export function AppHeaderBar(props: AppHeaderBarProps) {
    return (
        <AppBar
            position="static"
            color="transparent"
            elevation={1}
            sx={{
                position: 'relative'
            }}
        >
            <Container
                maxWidth={false}
            >
                <Toolbar
                    disableGutters
                >

                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={props.onClickMenu}
                        sx={{
                            p: 1.5,
                        }}
                    >
                        <Menu/>
                    </IconButton>

                    {props.children}

                </Toolbar>
            </Container>
        </AppBar>
    )
}
