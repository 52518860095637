import React from 'react'

import { Typography } from '@mui/material'

export function AppHeaderBarLogo() {
    return (
        <Typography
            variant="h6"
            sx={{
                ml: { xs:0.5, sm:1.5 },
            }}
        >

            <strong>Coinconv</strong>

        </Typography>
    )
}
