import React from 'react'

import { Drawer, Box, List } from '@mui/material'
import { CurrencyExchange, InfoOutlined } from '@mui/icons-material'
import { AppHeaderBar } from './AppHeaderBar'
import { AppHeaderBarLogo } from './AppHeaderBarLogo'
import { AppHeaderNavLink } from './AppHeaderNavLink'

export interface AppHeaderNavProps {
    open: boolean
    onClose: () => void
}

export function AppHeaderNav(props: AppHeaderNavProps) {

    return (
        <Drawer
            anchor="left"
            open={props.open}
            onClose={props.onClose}
        >

            <AppHeaderBar
                onClickMenu={props.onClose}
            >
                <AppHeaderBarLogo/>
            </AppHeaderBar>

            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'space-between',
                    overflowY: 'auto'
                }}
            >

                <List>

                    <AppHeaderNavLink
                        icon={<CurrencyExchange/>}
                        text="Currency converter"
                        to="/"
                    />

                </List>

                <List>

                    <AppHeaderNavLink
                        icon={<InfoOutlined/>}
                        text="About app"
                        to="/about"
                    />

                </List>

            </Box>

        </Drawer>
    )
}
