export type CurrencyId = string

export type Currency = {
    exchange: {
        [key: CurrencyId]: CurrencyExchange
    }
}

export type CurrencyExchange = {
    [key in CurrencyExchangeType]: number
}

export type CurrencyExchangeType = 'buy' | 'sell'

export type Currencies = {
    [key: CurrencyId]: Currency
}

export const currencies: Currencies = {
    'GEL': {
        exchange: {
            'RUB': {
                buy: 0.037,
                sell: 0.0385,
            },
            'USD': {
                buy: 3,
                sell: 3.025,
            },
            'EUR': {
                buy: 3.15,
                sell: 3.19,
            },
        },
    },
    'RUB': {
        exchange: {
            'GEL': {
                buy: 0,
                sell: 0,
            },
            'USD': {
                buy: 0,
                sell: 0,
            },
            'EUR': {
                buy: 0,
                sell: 0,
            },
        },
    },
    'USD': {
        exchange: {
            'RUB': {
                buy: 0,
                sell: 0,
            },
            'GEL': {
                buy: 0,
                sell: 0,
            },
            'EUR': {
                buy: 0,
                sell: 0,
            },
        },
    },
    'EUR': {
        exchange: {
            'RUB': {
                buy: 0,
                sell: 0,
            },
            'GEL': {
                buy: 0,
                sell: 0,
            },
            'USD': {
                buy: 0,
                sell: 0,
            },
        },
    },
}

export function getExchangeRate(a: CurrencyId, b: CurrencyId, exchangeType: CurrencyExchangeType) {
    const aCurrency = currencies[a]!
    const abCurrencyExchange = aCurrency.exchange[b]
    return (abCurrencyExchange && abCurrencyExchange[exchangeType]) || 1
}

export function getAValue(bValue: number, exchangeRate: number) {
    return Math.floor((bValue * exchangeRate) * 100) / 100
}

export function getBValue(aValue: number, exchangeRate: number) {
    return Math.floor((aValue / exchangeRate) * 100) / 100
}
