import React from 'react'
import * as Styles from './Conv.styles'
import { reducer, initialState } from './Conv.reducer'
import { CurrencyId } from '../../services/ConvService'
import { Box, IconButton, InputBase } from '@mui/material'
import { ArrowCircleUpTwoTone, ArrowCircleDownTwoTone } from '@mui/icons-material'

export function Conv() {

    const [ state, dispatch ] = React.useReducer(reducer, initialState)

    const handleChangeAValue = React.useCallback(({ target:{ valueAsNumber } }: React.ChangeEvent<HTMLInputElement>) => {
        const aValue = valueAsNumber
        dispatch({ type:'setAValue', payload:aValue })
    }, [])

    const handleChangeBValue = React.useCallback(({ target:{ valueAsNumber } }: React.ChangeEvent<HTMLInputElement>) => {
        const bValue = valueAsNumber
        dispatch({ type:'setBValue', payload:bValue })
    }, [])

    return (
        <Box flexGrow={1} display="flex" flexDirection="column">
            <Styles.Currencies
                centered
                value={state.a}
                onChange={(e, a: CurrencyId) => {
                    dispatch({ type:'setA', payload:a })
                }}
            >
                {Object.entries(state.currencies).map(([k, v]) => {
                    return (
                        <Styles.CurrenciesCurrency key={k} label={k} value={k}/>
                    )
                })}
            </Styles.Currencies>
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center">
                <InputBase
                    type="number"
                    value={state.aValue}
                    onChange={handleChangeAValue}
                    sx={{
                        fontSize:48,
                        '& .MuiInputBase-input': {
                            textAlign: 'center'
                        }
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Styles.Exchange
                    active={state.exchangeType === 'buy'}
                >
                    <Styles.ExchangeButton
                        color={state.exchangeType === 'buy' ? 'primary' : 'default'}
                        onClick={() => {
                            dispatch({ type:'setExchangeType', payload:'buy' })
                        }}
                    >
                        <ArrowCircleUpTwoTone sx={{ width:48, height:48 }}/>
                    </Styles.ExchangeButton>
                    {state.exchangeType === 'buy' && (
                        <Styles.ExchangeInput
                            type="number"
                            fullWidth
                            value={state.exchangeRate}
                            onChange={({ target:{ valueAsNumber }}: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch({ type:'setExchangeRate', payload:valueAsNumber })
                            }}
                            sx={{
                                fontSize:24,
                                '& .MuiInputBase-input': {
                                    textAlign: 'center'
                                }
                            }}
                        />
                    )}
                </Styles.Exchange>
                <Styles.Exchange
                    active={state.exchangeType === 'sell'}
                >
                    {state.exchangeType === 'sell' && (
                        <Styles.ExchangeInput
                            type="number"
                            fullWidth
                            value={state.exchangeRate}
                            onChange={({ target:{ valueAsNumber }}: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch({ type:'setExchangeRate', payload:valueAsNumber })
                            }}
                            sx={{
                                fontSize:24,
                                '& .MuiInputBase-input': {
                                    textAlign: 'center'
                                }
                            }}
                        />
                    )}
                    <IconButton
                        color={state.exchangeType === 'sell' ? 'primary' : 'default'}
                        onClick={() => {
                            dispatch({ type:'setExchangeType', payload:'sell' })
                        }}
                    >
                        <ArrowCircleDownTwoTone sx={{ width:48, height:48 }}/>
                    </IconButton>
                </Styles.Exchange>
            </Box>
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center">
                <InputBase
                    type="number"
                    value={state.bValue}
                    onChange={handleChangeBValue}
                    sx={{
                        fontSize:48,
                        '& .MuiInputBase-input': {
                            textAlign: 'center'
                        }
                    }}
                />
            </Box>
            <Styles.Currencies
                centered
                value={state.b}
                onChange={(e, b: CurrencyId) => {
                    dispatch({ type:'setB', payload:b })
                }}
            >
                {Object.entries(state.currencies).map(([k, v]) => {
                    return (
                        <Styles.CurrenciesCurrency key={k} label={k} value={k}/>
                    )
                })}
            </Styles.Currencies>
        </Box>
    )
}
