import React from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { AppHeaderBar } from './AppHeaderBar'
import { AppHeaderBarLogo } from './AppHeaderBarLogo'
import { AppHeaderNav } from './AppHeaderNav'

type AppHeaderLocationState = {
    AppHeader?: {
        navOpen?: boolean
    }
}

export function AppHeader() {

    const location = useLocation()
    const navigate = useNavigate()

    const open = React.useMemo(() => {
        const state = location.state as AppHeaderLocationState | null
        return !!state?.AppHeader?.navOpen
    }, [location.state])

    const onOpen = React.useCallback(() => {
        navigate(location.pathname, { state:{ AppHeader:{ navOpen:true } } })
    }, [navigate, location.pathname])

    const onClose = React.useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <>

            <AppHeaderBar
                onClickMenu={onOpen}
            >
                <AppHeaderBarLogo/>
            </AppHeaderBar>

            <AppHeaderNav
                open={open}
                onClose={onClose}
            />

        </>
    )
}
