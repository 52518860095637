import React from 'react'

import { useMatch, useResolvedPath } from 'react-router-dom'

import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom'

export interface AppHeaderNavLinkProps {
    icon: React.ReactNode
    text: React.ReactNode
    to: string
}

export function AppHeaderNavLink(props: AppHeaderNavLinkProps) {

    const resolved = useResolvedPath(props.to)
    const match = useMatch({ path:resolved.pathname, end:true })

    return (
        <ListItem
            disablePadding
            selected={!!match}
        >
            <ListItemButton
                component={Link}
                replace
                to={props.to}
                sx={{
                    px: { xs:2, sm:3 },
                }}
            >

                <ListItemIcon
                    sx={{
                        minWidth: { xs:40, sm:48 }
                    }}
                >
                    {props.icon}
                </ListItemIcon>

                <ListItemText
                    primary={props.text}
                />

            </ListItemButton>
        </ListItem>
    )
}
