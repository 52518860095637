import React from 'react'
import ReactDOM from 'react-dom/client'
import 'index.css'

import { ThemeProvider, CssBaseline } from '@mui/material'
import { theme } from 'theme'
import { BrowserRouter } from 'react-router-dom'
import { App } from 'packs/app/components/App/App'

import reportWebVitals from 'reportWebVitals'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
)

reportWebVitals()
